import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Layout from '../components/layout';

interface Props extends RouteComponentProps{}

const HelloWorld : React.FC<Props> = () => { 
    return (
        <Layout>
           Coming Soon!
        </Layout>
    );
}

export default HelloWorld;
