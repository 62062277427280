import React from "react";
import {Router} from "@reach/router"
import HelloWorld from "../modules/helloWorld";
import Layout from "../components/layout";
import SEO from "../components/seo";
const AppRouter = () => {
    return (
    <Layout>
        <SEO title={"App"}/>
        <Router>
            <HelloWorld path="/app/hello"/>
        </Router>
    </Layout>
        
    )
}

export default AppRouter;